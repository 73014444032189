//React
import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, Redirect } from 'react-router-dom';

//Firebase
import { db } from '../component/firebase';
import { collection, doc, query, orderBy, setDoc, getDocs, deleteDoc, Timestamp } from "firebase/firestore";

//Auth
import { useAuthContext } from '../component/auth';

//MUI
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import { blue } from '@mui/material/colors';

//Icon
import SearchIcon from '@mui/icons-material/Search';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CloseIcon from '@mui/icons-material/Close';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

//component
import Head from "../component/Head";
import Layout from '../component/Layout';
import { LazyLoadImage } from 'react-lazy-load-image-component';

//json 
import usedShopData from '../json/usedShop.json';

//SearchContents
const SearchContents = (props) => {

  const pageUrl = process.env.REACT_APP_API_ENDPOINT;

  const location = props.location;

  const isSignedIn = props.isSignedIn;

  const [searchConfig, setSearchConfig] = useState({});
  const [searchData, setSearchData] = useState([]);
  const [viewSavedInfo, setViewSavedInfo] = useState(false);
  const [viewNothingInfo, setViewNothingInfo] = useState(false);
  const [productClear, setProductClear] = useState(false);
  const [searchProgress, setSearchProgress] = useState(false);
  const [alertStatus, setAlertStatus] = useState({ open: false });
  const [componentMounted, setComponentMounted] = useState(true);

  const createRandomString = () => {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for ( var i = 0; i < 28; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  };

  const checkProduct = (data) => {
    const productBool = data.map((dataObj) => {
      if (dataObj.productData.length) {
        return true;
      }
      return false;
    }).filter(Boolean);
    if (productBool.includes(true)) {
      setProductClear(true);
    } else {
      setProductClear(false);
    }
  }

  const getGenre = async (value, id) => {
    const url = 'https://rakuten_webservice-rakuten-marketplace-genre-search-v1.p.rapidapi.com/services/api/IchibaGenre/Search/20120723'
    const format = 'json';
    const genre = value;
    const affiliateId = '0f6adda6.9b95f771.0f6adda7.ce97c4be';
    try {
      const response = await fetch (`${url}?format=${format}&genreId=${genre}&affiliateId=${affiliateId}`, {
        "method": "GET",
        "headers": {
          "x-rapidapi-key": "e904767b60msh9217f0c6f30ee27p10f525jsnd57d72c37de7",
          "x-rapidapi-host": "rakuten_webservice-rakuten-marketplace-genre-search-v1.p.rapidapi.com"
        }
      });
      const json = await response.json();
      if (json.children.length !== 0) {
        const genreArray = {...json.children};
        const newSearchData = searchData.map((searchDataItem) => {
          if (searchDataItem.id === id) {
            searchDataItem.genreData = [...searchDataItem.genreData, genreArray];
            searchDataItem.productData = [];
            searchDataItem.errorData = [];
            searchDataItem.page = 0;
            searchDataItem.prev = true;
          }
          return searchDataItem;
        });
        if (componentMounted) {
          setSearchData([...newSearchData]);
        }
      }
    }
    catch(error) {
      setAlertStatus({
        open: true,
        type: 'error',
        title: 'ジャンルが取得できませんでした',
        message: `${error}`,
      });
    }
  };

  const getProduct = async (obj) => {
    const url = 'https://rakuten_webservice-rakuten-marketplace-item-search-v1.p.rapidapi.com/IchibaItem/Search/20170706'
    const format = 'json';
    const keyword = encodeURI(obj.searchValue.keyword);
    const sort = encodeURIComponent(obj.searchValue.sort);
    const genre = obj.searchValue.genre.slice(-1)[0];
    const requestPage = obj.page + 1;
    const affiliateId = '0f6adda6.9b95f771.0f6adda7.ce97c4be';
    if (obj.prev) {
      try {
        const response = await fetch (`${url}?format=${format}&keyword=${keyword}&sort=${sort}&genreId=${genre}&page=${requestPage}&field=1&affiliateId=${affiliateId}`, {
          "method": "GET",
          "headers": {
            "x-rapidapi-key": "e904767b60msh9217f0c6f30ee27p10f525jsnd57d72c37de7",
            "x-rapidapi-host": "rakuten_webservice-rakuten-marketplace-item-search-v1.p.rapidapi.com"
          }
        });
        const json = await response.json();
        if (typeof json.error === "undefined") {
          if (json.Items.length) {           
            const originProductData = obj.productData;
            const addProductData = json.Items;
            const newProductData = originProductData.concat(addProductData);
            obj.productData = [...newProductData];
            obj.page = json.page;
            if (json.page === json.pageCount) {
              obj.prev = false;
            }
            obj.count = json.count;
            obj.last = json.last;
            obj.pageCount = json.pageCount;
          } else {
            const errorData = [{
              error: '商品がありません',
              error_description: '条件を変更してから再度お試しください。',
            }];
            obj.errorData = [...errorData];
          }
        } else {
          const errorData = [{
            error: json.error,
            error_description: json.error_description,
          }];
          obj.errorData = [...errorData];
        }
        if (componentMounted) {
          setSearchData([...searchData]);
          checkProduct(searchData);
        }
      }
      catch(error) {
        setAlertStatus({
          open: true,
          type: 'error',
          title: '商品が取得できませんでした',
          message: `${error}`,
        });
      }
    }
  };

  const getSearchConfig = async () => {
    try {
      const configQuery = query(collection(db, 'users', isSignedIn.uid, 'config'), orderBy('rank'));
      const querySnapshot = await getDocs(configQuery);
      if (querySnapshot.size) {
        setSearchConfig(querySnapshot);
        setViewSavedInfo(true);
      } else {
        handleAddSearchData(searchData.length + 1);
      }
    }
    catch(error) {
      setAlertStatus({
        open: true,
        type: 'error',
        title: '保存した検索条件が取得できませんでした',
        message: `${error}`,
      });
    }
  }

  //保存済み検索設定
  const handleApplySearchClick = () => {
    const newSearchData = [];
    searchConfig.forEach((doc) => {
      const docData = doc.data();
      const addSearchData = {};
      addSearchData['savedDocId'] = doc.id;
      addSearchData['createDate'] = docData.createDate;
      addSearchData['rank'] = docData.rank;
      addSearchData['id'] = docData.id;
      addSearchData['searchValue'] = docData.searchValue;
      addSearchData['genreData'] = docData.genreData;
      addSearchData['productData'] = [];
      addSearchData['errorData'] = [];
      addSearchData['page'] = 0;
      addSearchData['prev'] = true;
      newSearchData.push(addSearchData);
    });
    setSearchData([...newSearchData]);
    setProductClear(false);
    setViewSavedInfo(false);
    setViewNothingInfo(false);
  };

  const handleInfoCloseClick = () => {
    setViewSavedInfo(false);
    handleAddSearchData(searchData.length + 1);
  }

  //検索設定
  const handleAddSearchData = (index) => {
    const newSearchData = searchData.map((item) => {
      item.productData = [];
      item.errorData = [];
      item.page = 0;
      item.prev = true;
      return item;
    });
    const addSearchData = {};
    addSearchData['createDate'] = isSignedIn ? Timestamp.fromDate(new Date()) : '';
    addSearchData['rank'] = index;
    addSearchData['id'] = createRandomString();
    addSearchData['searchValue'] = {
      keyword: '',
      genre: [],
      sort: '',
    };
    addSearchData['genreData'] = [];
    addSearchData['productData'] = [];
    addSearchData['errorData'] = [];
    addSearchData['page'] = 0;
    addSearchData['prev'] = true;
    setSearchData([...newSearchData, addSearchData]);
    setViewNothingInfo(false);
  };

  const handleRemoveSearchData = (searchDataObj) => {
    const newSearchData = searchData.filter((item) => item.id !== searchDataObj.id);
    setSearchData([...newSearchData]);
    checkProduct(newSearchData);
    if (!newSearchData.length) {
      setViewNothingInfo(true);
    }
  }

  const handleKeywordChange = (event, id) => {
    const newSearchData = searchData.map((item) => {
      if (item.id === id) {
        item.searchValue.keyword = event.target.value;
        item.productData = [];
        item.errorData = [];
        item.page = 0;
        item.prev = true;
      }
      return item;
    });
    setSearchData([...newSearchData]);
    checkProduct(newSearchData);
  };

  const handleGenreChange = (event, index, id) => {
    const newSearchData = searchData.map((item) => {
      if (item.id === id) {
        const newGenreValue = [...item.searchValue.genre];
        newGenreValue[index] = event.target.value;
        newGenreValue.splice(index + 1);
        if (!event.target.value) {
          newGenreValue.splice(-1,1);
        }
        item.searchValue.genre = newGenreValue;
        item.productData = [];
        item.errorData = [];
        item.page = 0;
        item.prev = true;
        item.genreData.splice(index);
      }
      return item;
    });
    setSearchData([...newSearchData]);
    checkProduct(newSearchData);
    if (event.target.value) {
      getGenre(event.target.value, id);
    } 
  };

  const handleSortChange = (event, id) => {
    const newSearchData = searchData.map((item) => {
      if (item.id === id) {
        item.searchValue.sort = event.target.value;
        item.productData = [];
        item.errorData = [];
        item.page = 0;
        item.prev = true;
      }
      return item;
    });
    setSearchData([...newSearchData]);
    checkProduct(newSearchData);
  };

  const handleSaveSearchConditionsClick = async (searchDataObj) => {
    try {
      const configDocRef = doc(collection(db, 'users', isSignedIn.uid, 'config'));
      const configData = {
        createDate: searchDataObj.createDate,
        rank: searchDataObj.rank,
        id: searchDataObj.id,
        searchValue: searchDataObj.searchValue,
        genreData: searchDataObj.genreData,
      };
      await setDoc(configDocRef, configData);
      const newSearchData = searchData.map((item) => {
        if (item.id === searchDataObj.id) {
          item.savedDocId = configDocRef.id;
        }
        return item;
      });
      setSearchData([...newSearchData]);
      setAlertStatus({
        open: true,
        type: 'success',
        message: '検索条件を保存しました',
      });
    }
    catch(error) {
      setAlertStatus({
        open: true,
        type: 'error',
        title: '検索条件の保存に失敗しました',
        message: `${error}`,
      });
    }
  };

  const handleUpdateSearchConditionsClick = async (searchDataObj) => {
    try {
      const configDocRef = doc(db, 'users', isSignedIn.uid, 'config', searchDataObj.savedDocId);
      const configData = {
        createDate: searchDataObj.createDate,
        rank: searchDataObj.rank,
        id: searchDataObj.id,
        searchValue: searchDataObj.searchValue,
        genreData: searchDataObj.genreData,
      };
      await setDoc(configDocRef, configData);
      setAlertStatus({
        open: true,
        type: 'success',
        message: '検索条件を更新しました',
      });
    }
    catch(error) {
      setAlertStatus({
        open: true,
        type: 'error',
        title: '検索条件の更新に失敗しました',
        message: `${error}`,
      });
    }
  };

  const handleDeleteSearchConditionsClick = async (searchDataObj) => {
    try {
      //delete
      const configDocRef = doc(db, 'users', isSignedIn.uid, 'config', searchDataObj.savedDocId);
      await deleteDoc(configDocRef);
      //searchConfig update
      const configQuery = query(collection(db, 'users', isSignedIn.uid, 'config'), orderBy('rank'));
      const querySnapshot = await getDocs(configQuery);
      if (querySnapshot.size) {
        setSearchConfig(querySnapshot);
      } else {
        setSearchConfig({});
      }
      handleRemoveSearchData(searchDataObj);
      setAlertStatus({
        open: true,
        type: 'success',
        message: '検索条件を削除しました',
      });
    }
    catch(error) {
      setAlertStatus({
        open: true,
        type: 'error',
        title: '検索条件の削除に失敗しました',
        message: `${error}`,
      });
    }
  }

  //検索実行
  const handleSearchClick = async () => {

    setSearchProgress(true);
    
    if (searchData.length) {
      await Promise.all(searchData.map(async (searchDataObj) => {
        return await getProduct(searchDataObj);
      }))
        .then(() => {
          setSearchProgress(false)
        });
    } else {
      setAlertStatus({
        open: true,
        type: 'error',
        title: '検索条件が設定されていません',
        message: '検索条件を設定してから検索を実行してみてください。',
      });
      setSearchProgress(false);
    }
    
  };

  const handleClearClick = () => {
    const newSearchData = searchData.map((item) => {
      item.productData = [];
      item.errorData = [];
      item.page = 0;
      item.prev = true;
      return item;
    });
    setSearchData([...newSearchData]);
    checkProduct(newSearchData);
  }
  
  const handleMoreClick = (searchDataObj) => {
    getProduct(searchDataObj);
  };

  //商品リンク設定
  const handleProductClick = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  };

  //alert
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertStatus({
      open: false,
    });
  };

  useEffect(() => {

    if (isSignedIn) {
      getSearchConfig();
    } else {
      handleAddSearchData(searchData.length + 1);
    }

    return () => {
      setComponentMounted(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Head
        pageTitle="検索一覧 | 楽天市場のファッションアイテムまとめて検索 ファッション R-SEARCH"
        pageDesc={`ファッションR-SEARCHは楽天市場でのファッションアイテムに関する商品検索を少し便利にするサイトです。`}
        pageUrl={`${pageUrl}${location.pathname}`}
      />
      <Layout>
        <Box component='section' sx={{ pb: 2, minHeight: { xs: 'calc(100vh - 56px - 60px)', md: 'calc(100vh - 64px - 60px)', }}}>
          <Box
            mx={{ xs: -2, md: -3 }}
            p={{ xs: 2, md: 3 }}
            sx={{
              height: '320px',
              backgroundColor: blue[50],
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              zIndex: 1,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                width:  { xs:'240%', sm:'180%', md: '120%', },
                position: 'absolute',
                top: '0%',
                right: '0%',
                transform: 'translate(50%, -50%) rotate(15deg);',
                zIndex: 1,
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 294.394 98.726">
                <path
                  d="M239.972 95.168c-29.55 6.52-83.26 4.964-99.002-12.144-9.489-10.081-16.086-16.763-28.702-17.842q-2.055-.188-4.239-.175c-11.443.062-26.542 4.399-39.898 12.272-13.878 8.444-27.037 12.525-41.056 11.772-27.91-1.76-34.39-19.34-18.75-37.236C23.963 33.576 62.84 17.374 84.814 16.98c12.222-.175 21.998 3.982 25.418 11.488 2.865 7.02 9.31 10.95 19.857 10.96q1.953.003 4.054-.176c12.371-1.115 28.287-6.906 35.015-15.73C179.593 9.462 176.08-1.42 190.154.151c11.165 1.357 28.304 10.608 45.132 21.402 14.592 9.551 31.558 20.036 45.585 28.335 14.153 8.32 17.493 14.618 8.615 22.911-10.346 9.363-24.227 17.432-49.514 22.369Z"
                  fill={blue[100]}
                />
              </svg>
            </Box>
            <Box
              sx={{
                width: { xs:'200%', sm:'150%', md: '100%', },
                position: 'absolute',
                top: '100%',
                left: '0%',
                transform: 'translate(-50%, -50%) rotate(190deg);',
                zIndex: 2,
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 294.394 98.726">
                <path
                  d="M239.972 95.168c-29.55 6.52-83.26 4.964-99.002-12.144-9.489-10.081-16.086-16.763-28.702-17.842q-2.055-.188-4.239-.175c-11.443.062-26.542 4.399-39.898 12.272-13.878 8.444-27.037 12.525-41.056 11.772-27.91-1.76-34.39-19.34-18.75-37.236C23.963 33.576 62.84 17.374 84.814 16.98c12.222-.175 21.998 3.982 25.418 11.488 2.865 7.02 9.31 10.95 19.857 10.96q1.953.003 4.054-.176c12.371-1.115 28.287-6.906 35.015-15.73C179.593 9.462 176.08-1.42 190.154.151c11.165 1.357 28.304 10.608 45.132 21.402 14.592 9.551 31.558 20.036 45.585 28.335 14.153 8.32 17.493 14.618 8.615 22.911-10.346 9.363-24.227 17.432-49.514 22.369Z"
                  fill={blue[100]}
                />
              </svg>
            </Box>
            <Box
              sx={{
                px: 2,
                mb: 3,
                position: 'relative',
                zIndex: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                component="h1"
                sx={{
                  color: 'secondary.dark',
                  fontSize: { xs:'30px', md: '40px', },
                  fontWeight: 'bold',
                  lineHeight: { xs:'1.4', md: '1.35', },
                  fontFeatureSettings: "'palt'",
                  letterSpacing: '0.1em',
                }}
              >
                検索一覧
              </Typography>
              <Typography
                sx={{
                  color: 'secondary.dark',
                  fontWeight: 'bold',
                  mt: 1.5,
                  letterSpacing: '0.1em',
                  fontFamily: [
                    'Roboto',
                    'sans-serif',
                  ].join(','),
                }}
              >
                Search list
              </Typography>
            </Box>
          </Box>
          <Box
            mt={-5}
            sx={{
              maxWidth: '1480px',
              marginLeft: 'auto',
              marginRight: 'auto',
              position: 'relative',
              zIndex: 2,
            }}
          >
            {viewNothingInfo && !viewSavedInfo && (
              <Box my={2}>
                <Paper sx={{ p: 2, }}>
                  <Alert
                    severity="info"
                    sx={{
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: 'info.main',
                    }}
                  >
                    <AlertTitle sx={{ fontWeight: 'fontWeightBold', }}>検索条件を追加しましょう</AlertTitle>
                    下の検索条件を追加ボタンを押して、検索条件を追加してください。
                    {Object.keys(searchConfig).length > 0 && (
                      <React.Fragment>
                        <br />保存した検索条件で検索を行う場合は<Link component={RouterLink} to="#" onClick={handleApplySearchClick}>こちら</Link>
                      </React.Fragment>
                    )}
                  </Alert>
                </Paper>
              </Box>
            )}
            {viewSavedInfo && (        
              <Box my={2}>
                <Paper sx={{ p: 2, }}>
                  <Alert
                    severity="info"
                    sx={{
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: 'info.main',
                    }}
                    action={
                      <IconButton
                        color="info"
                        size="small"
                        sx={{ mt: -0.5, }}
                        onClick={handleInfoCloseClick}
                        aria-label="インフォメーションを閉じる"
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                  >
                    <AlertTitle sx={{ fontWeight: 'fontWeightBold', }}>保存した検索条件があります</AlertTitle>
                    保存済みの条件で検索を行う際は適用ボタンを押してください。
                    <Box mt={1.5}>
                      <Button
                        variant="contained"
                        color="info"
                        size="small"
                        disableElevation
                        onClick={handleApplySearchClick}
                      >
                        適用
                      </Button>
                    </Box>
                  </Alert>
                </Paper>
              </Box>
            )}
            <Box>
              {searchData.map((searchDataObj, index) => {
                const searchIndex = index + 1;
                return (
                  <Box my={2} key={searchDataObj.id}>
                    <Paper sx={{ p: 2, }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mx: -2,
                          mt: -2,
                          mb: 2,
                          p: 2,
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: 'divider',
                        }}
                      >
                        <SearchIcon />
                        <Typography
                          component='p'
                          variant='h6'
                          sx={{
                            flexGrow: 1,
                            fontWeight: 'fontWeightBold',
                            ml: 1,
                          }}
                        >
                          検索条件&nbsp;{searchIndex}
                        </Typography>
                        <IconButton
                          color="inherit"
                          size="small"
                          onClick={() => handleRemoveSearchData(searchDataObj)}
                          aria-label="検索条件の削除"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      <Box py={0.5}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="keyword">検索ワード</InputLabel>
                              <OutlinedInput
                                id="keyword"
                                label="検索ワード"
                                defaultValue={searchDataObj.searchValue.keyword}
                                onBlur={(event) => handleKeywordChange(event, searchDataObj.id)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <InputLabel id="genre">ジャンル</InputLabel>
                              <Select
                                labelId="genre"
                                label="ジャンル"
                                value={searchDataObj.searchValue.genre.length > 0 ? searchDataObj.searchValue.genre[0] : ''}
                                onChange={(event) => handleGenreChange(event, 0, searchDataObj.id)}
                              >
                                <MenuItem value="">未選択</MenuItem>
                                <MenuItem value={100371}>レディースファッション</MenuItem>
                                <MenuItem value={551177}>メンズファッション</MenuItem>
                                <MenuItem value={558885}>靴</MenuItem>
                                <MenuItem value={558929}>腕時計</MenuItem>
                                <MenuItem value={216129}>ジュエリー・アクセサリー</MenuItem>
                                <MenuItem value={216131}>バッグ・小物・ブランド雑貨</MenuItem>
                              </Select>
                            </FormControl>
                            {searchDataObj.genreData.map((genreDataArray, index) => {
                              const genreIndex = index + 1;
                              return (
                                <Box mt={2} key={genreIndex}>
                                  <FormControl fullWidth>
                                    <InputLabel id={`${searchDataObj.id}_genre${genreIndex}`}>ジャンル</InputLabel>
                                    <Select
                                      labelId={`${searchDataObj.id}_genre${genreIndex}`}
                                      label="ジャンル"
                                      value={searchDataObj.searchValue.genre[genreIndex] ? searchDataObj.searchValue.genre[genreIndex] : ''}
                                      onChange={(event) => handleGenreChange(event, genreIndex, searchDataObj.id)}
                                    >
                                      <MenuItem value="">未選択</MenuItem>
                                      {Object.keys(genreDataArray).map((item) => {
                                        return (
                                          <MenuItem value={genreDataArray[item].child.genreId} key={genreDataArray[item].child.genreId}>{genreDataArray[item].child.genreName}</MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </Box>
                              );
                            })}
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <InputLabel id="sort">並び順</InputLabel>
                              <Select
                                labelId="sort"
                                label="並び順"
                                value={searchDataObj.searchValue.sort}
                                onChange={(event) => handleSortChange(event, searchDataObj.id)}
                              >
                                <MenuItem value="">未選択</MenuItem>
                                <MenuItem value={'+itemPrice'}>価格が低い順</MenuItem>
                                <MenuItem value={'-itemPrice'}>価格順が高い順</MenuItem>
                                <MenuItem value={'+updateTimestamp'}>更新日が古い順</MenuItem>
                                <MenuItem value={'-updateTimestamp'}>更新日が新しい</MenuItem>
                                <MenuItem value={'standard'}>楽天標準ソート順</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>            
                        </Grid>
                      </Box>
                      {searchDataObj.savedDocId ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            mx: -2,
                            mt: 2,
                            mb: -2,
                            p: 2,
                            borderTopStyle: 'solid',
                            borderTopWidth: '1px',
                            borderTopColor: 'divider',
                          }}
                        >
                          <Stack direction="row" spacing={1}>
                            <Button
                              variant="outlined"
                              color='secondary'
                              size="small"
                              startIcon={<RefreshOutlinedIcon sx={{ mr: -0.5, }} />}
                              onClick={() => handleUpdateSearchConditionsClick(searchDataObj)}
                            >
                              更新
                            </Button>
                            <Button
                              color='secondary'
                              size="small"
                              startIcon={<DeleteForeverOutlinedIcon sx={{ mr: -0.5, }} />}
                              onClick={() => handleDeleteSearchConditionsClick(searchDataObj)}
                            >
                              削除
                            </Button>
                          </Stack>
                        </Box>
                      ) : (
                        isSignedIn && searchDataObj.searchValue.keyword && searchDataObj.searchValue.genre.length > 0 && searchDataObj.searchValue.sort && (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              mx: -2,
                              mt: 2,
                              mb: -2,
                              p: 2,
                              borderTopStyle: 'solid',
                              borderTopWidth: '1px',
                              borderTopColor: 'divider',
                            }}
                          >
                            <Button
                              variant="outlined"
                              color='secondary'
                              size="small"
                              startIcon={<BackupOutlinedIcon sx={{ mr: -0.25, }} />}
                              onClick={() => handleSaveSearchConditionsClick(searchDataObj)}
                            >
                              保存
                            </Button>
                          </Box>
                        )
                      )}
                    </Paper>
                  </Box>
                );
              })}
            </Box>
            <Box my={3}>
              <Button
                variant="contained"
                color='secondary'
                size="small"
                disableElevation
                onClick={() => handleAddSearchData(searchData.length + 1)}
              >
                検索条件を追加
              </Button>
            </Box>
            <Divider />
            <Box my={4} sx={{ display: 'flex', justifyContent: 'center', }}>
              <Stack direction="row" spacing={1}>
                <LoadingButton
                  color='primary'
                  variant="contained"
                  onClick={handleSearchClick}
                  loading={searchProgress}
                >
                  商品を検索
                </LoadingButton>
                {productClear && !searchProgress && <Button color='primary' onClick={handleClearClick}>商品クリア</Button> }
              </Stack>
            </Box>
            {!searchProgress && (
              <React.Fragment>
                {searchData.map((searchDataObj, index) => {
                  const searchIndex = index + 1;
                  return (
                    <Box my={4} key={index} component='section'>
                      {searchDataObj.productData.length > 0 && (
                        <React.Fragment key={index}>
                          <Divider />
                          {searchDataObj.searchValue.keyword.length > 0 && (     
                            <Box my={2} sx={{ display: 'flex', alignItems: 'center', }}>
                              <Box sx={{ flexGrow: 1, }}>
                                <Typography
                                  component="h2"
                                  sx={{
                                    fontSize: { xs:'24px', md: '34px', },
                                    fontWeight: 'fontWeightBold',
                                    wordBreak: 'break-all',
                                    lineHeight: 1.4,
                                  }}
                                >
                                  {searchDataObj.searchValue.keyword}
                                </Typography>
                                <Typography
                                  color='text.disabled'
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: 'fontWeightBold',
                                  }}
                                >
                                  {searchDataObj.last}&nbsp;/&nbsp;{searchDataObj.count}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                          <Grid container spacing={{ xs: 1, sm: 2 }}>
                              {searchDataObj.productData.map((item, index) => {
                                const originImgUrl = item.Item.mediumImageUrls.length > 0 ? item.Item.mediumImageUrls[0].imageUrl : '';
                                const imgUrl = originImgUrl ? originImgUrl.replace('?_ex=128x128', '?_ex=600x600') : '';
                                const orderPrice = Number(`${item.Item.itemPrice}`).toLocaleString('ja-JP', {
                                  style: 'currency',
                                  currency: 'JPY',
                                });
                                const itemName = item.Item.itemName;
                                const itemShopName = item.Item.shopName;
                                const itemShopCode = item.Item.shopCode;
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sm={4}
                                    md={4}
                                    lg={3}
                                    xl={2}
                                    sx={{ display: 'flex', }}
                                    key={`${item.Item.itemCode}_${index}`}
                                  >
                                    <Card sx={{ width: '100%', display: 'flex', }} component="section">
                                      <CardActionArea
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'stretch',
                                        }}
                                        onClick={() => handleProductClick(item.Item.affiliateUrl.length > 0 ? item.Item.affiliateUrl : item.Item.itemUrl)}
                                      >
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'relative',
                                            overflow: 'hidden',
                                            zIndex: 1,
                                            '&::before': {
                                              content: '""',
                                              display: 'block',
                                              width: '100%',
                                              paddingTop: '100%',
                                              position: 'relative',
                                              zIndex: 2,
                                            },
                                          }}
                                        >
                                          {originImgUrl && (
                                            <CardMedia
                                              component="div"
                                              sx={{
                                                width: 'auto',
                                                height: '100%',
                                                position: 'absolute',
                                                zIndex: 3,
                                              }}
                                            >
                                              <LazyLoadImage
                                                src={imgUrl}
                                                alt={itemName}
                                                width='auto'
                                                height='100%'
                                              />
                                            </CardMedia>
                                          )}
                                        </Box>
                                        <CardContent
                                          sx={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            p: { xs:1.5, sm:2, },
                                          }}
                                        >
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{ mb: { xs: 1.5, sm: 2, }, }}
                                          >
                                            {(itemName.includes('中古') || itemShopName.includes('古着') || usedShopData.shop.includes(itemShopCode))  ? (
                                              <Chip
                                                icon={<StarHalfIcon sx={{ fill: '#fff', }} />}
                                                label="中古"
                                                size="small"
                                                color='warning'
                                                sx={{
                                                  fontWeight: 'fontWeightBold',
                                                  padding: '0 4px',
                                                  '& .MuiChip-label': { transform: 'translateY(1px)', },
                                                }}
                                              />
                                            ) : (
                                              <Chip
                                                icon={<StarOutlineIcon sx={{ fill: '#fff', }} />}
                                                label="新品"
                                                size="small"
                                                color='info'
                                                sx={{
                                                  fontWeight: 'fontWeightBold',
                                                  padding: '0 4px',
                                                  '& .MuiChip-label': { transform: 'translateY(1px)', },
                                                }}
                                              />
                                            )}
                                          </Stack>
                                          <Typography
                                            component='h3'
                                            variant='body2'
                                            color='text.secondary'
                                            sx={{
                                              wordBreak: 'break-all',
                                              fontSize:  {xs:'0.6875rem', md: '0.75rem', },
                                              fontWeight: 'fontWeightBold',
                                              lineHeight: 1.5,
                                            }}
                                            gutterBottom
                                          >
                                            {itemName}
                                          </Typography>
                                          <Typography
                                            variant='h6'
                                            component='p'
                                            color='primary.main'
                                            sx={{ wordBreak: 'break-all', }}
                                            gutterBottom
                                          >
                                            {orderPrice}
                                          </Typography>
                                          <Box component='div' sx={{ flexGrow: 1, }}/>
                                          <Typography
                                            variant='caption'
                                            component='p'
                                            color='text.disabled'
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'flex-end',
                                              fontSize: '0.625rem',
                                            }}
                                          >
                                            {itemShopName}
                                          </Typography>
                                        </CardContent>
                                      </CardActionArea>
                                    </Card>
                                  </Grid>
                                );
                              })}
                          </Grid>
                          <Box
                            my={4}
                            display='flex'
                            justifyContent='center'
                          >
                            <Typography
                              color='text.disabled'
                              sx={{
                                fontWeight: 'fontWeightBold',
                              }}
                            >
                              現在{searchDataObj.count}件中{searchDataObj.last}件表示
                            </Typography>
                          </Box>
                          {(searchDataObj.productData.length > 0 && searchDataObj.prev) && (           
                            <Box my={4} sx={{ textAlign: 'center' }}>
                              <Button variant="outlined" onClick={() => handleMoreClick(searchDataObj)}>さらに表示する</Button>
                            </Box>
                          )}
                        </React.Fragment>
                      )}
                      {searchDataObj.errorData.length > 0 && searchDataObj.errorData.map((item) => (
                        <Alert
                          severity="error"
                          sx={{
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: 'error.main',
                          }}
                          key={index}
                        >
                          <AlertTitle sx={{ fontWeight: 'bold', }}>検索条件&nbsp;-&nbsp;{searchIndex}</AlertTitle>
                          {item.error_description}
                        </Alert>
                      ))}
                    </Box>
                  );
                })}
              </React.Fragment>
            )}
          </Box>
        </Box>
        <Snackbar
          open={alertStatus.open}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          sx={{ bottom: 50, }}
        >
          <Alert          
            severity={alertStatus.type}
            onClose={handleAlertClose}
            sx={{
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: `${alertStatus.type}.main`,
            }}
          >
            {alertStatus.title && <AlertTitle sx={{ fontWeight: 'fontWeightBold', }}>{alertStatus.title}</AlertTitle> }
            {alertStatus.message}
          </Alert>
        </Snackbar>
      </Layout>
    </React.Fragment>
  );
  
};

//Search
function Search (props) {

  const location = useLocation();

  const { isSignedIn } = useAuthContext();

  if (location.pathname.slice(-1) === '/') return <Redirect to={location.pathname.slice(0, -1)} />

  return (
    <SearchContents isSignedIn={isSignedIn} location={location}>
      {props.children}
    </SearchContents>
  );

}

export default Search;
