//React
import React, { useState, useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';

//Firebase
import { db } from '../component/firebase';
import { collection, query, orderBy, getDocs, } from "firebase/firestore";

//Auth
import { useAuthContext } from '../component/auth';

//MUI
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';

//Icon

//component

//json 


//SavedListContents
const SavedListContents = (props) => {

  const isSignedIn = props.isSignedIn;
  const drawerToggleFunc = props.drawerToggle;

  const location = useLocation();

  const [searchConfig, setSearchConfig] = useState([]);
  const [componentMounted, setComponentMounted] = useState(true);

  const getSearchConfig = async () => {
    try {
      const configQuery = query(collection(db, 'users', isSignedIn.uid, 'config'), orderBy('rank'));
      const querySnapshot = await getDocs(configQuery);
      if (querySnapshot.size) {
        const newSearchData = [];
        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          const addSearchData = {};
          addSearchData['savedDocId'] = doc.id;
          addSearchData['searchValue'] = docData.searchValue;
          newSearchData.push(addSearchData);
        });
        if (componentMounted) {
          setSearchConfig([...newSearchData]);
        }
      }
    }
    catch(error) {
      console.log(error);
    }
  }

  useEffect(() => {

    getSearchConfig();

    return () => {
      setComponentMounted(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Divider />
      <List component='nav'>
        <ListItem
          component='div'
          sx={{
            fontSize: '0.875rem',
            fontWeight: 'bold',
          }}
        >
          保存した条件
        </ListItem>
        <List component='div'>
          {searchConfig.map((item, index) => {
            return (
              <ListItemButton
                component={NavLink}
                to={`/selection/${item.savedDocId}`}
                key={index}
                selected={`/selection/${item.savedDocId}` === location.pathname}
                sx={{ fontSize: '0.75rem', }}
                onClick={drawerToggleFunc && (() => drawerToggleFunc())}
              >
                {item.searchValue.keyword}
              </ListItemButton>
            )
          })}
        </List>
      </List>
    </React.Fragment>
  );
  
};

//SavedList
function SavedList (props) {

  const { isSignedIn } = useAuthContext();

  const drawerToggleFunc = props.drawerToggle;

  if (!isSignedIn) return false;

  return (
    <SavedListContents isSignedIn={isSignedIn} drawerToggle={drawerToggleFunc}>
      {props.children}
    </SavedListContents>
  );

}

export default SavedList;
