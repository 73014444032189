//React
import React from 'react';
import { NavLink } from 'react-router-dom';

//MUI
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { blue } from '@mui/material/colors';

//component
import Head from "../component/Head";
import Layout from '../component/Layout';
import topImage from '../image/topImage.svg';
import recommendImage1 from '../image/recommend_image_1@2x.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

//json 
import listData from '../json/list.json';

function Top() {

  const pageUrl = process.env.REACT_APP_API_ENDPOINT;

  return (
    <React.Fragment>
      <Head
        pageTitle="楽天市場のファッションアイテムまとめて検索 ファッション R-SEARCH"
        pageDesc={`ファッションR-SEARCHは楽天市場でのファッションアイテムに関する商品検索を少し便利にするサイトです。`}
        pageUrl={`${pageUrl}`}
      />
      <Layout>
        <Box sx={{ minHeight: { xs: 'calc(100vh - 56px - 60px)', md: 'calc(100vh - 64px - 60px)', }}}>
          <Box
            mx={{ xs: -2, md: -3 }}
            p={{ xs: 2, md: 3 }}
            sx={{
              height: {xs: 'auto', md:'640px'},
              backgroundColor: blue[50],
              display: 'flex',
              flexDirection: {xs: 'column', md: 'row'},
              alignItems: 'center',
              position: 'relative',
              zIndex: 1,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                width: '150%',
                position: 'absolute',
                top: '0%',
                right: '0%',
                transform: 'translate(50%, -50%) rotate(10deg);',
                zIndex: 1,
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 294.394 98.726">
                <path
                  d="M239.972 95.168c-29.55 6.52-83.26 4.964-99.002-12.144-9.489-10.081-16.086-16.763-28.702-17.842q-2.055-.188-4.239-.175c-11.443.062-26.542 4.399-39.898 12.272-13.878 8.444-27.037 12.525-41.056 11.772-27.91-1.76-34.39-19.34-18.75-37.236C23.963 33.576 62.84 17.374 84.814 16.98c12.222-.175 21.998 3.982 25.418 11.488 2.865 7.02 9.31 10.95 19.857 10.96q1.953.003 4.054-.176c12.371-1.115 28.287-6.906 35.015-15.73C179.593 9.462 176.08-1.42 190.154.151c11.165 1.357 28.304 10.608 45.132 21.402 14.592 9.551 31.558 20.036 45.585 28.335 14.153 8.32 17.493 14.618 8.615 22.911-10.346 9.363-24.227 17.432-49.514 22.369Z"
                  fill={blue[100]}
                />
              </svg>
            </Box>
            <Box
              sx={{
                width: '125%',
                position: 'absolute',
                top: '100%',
                left: '0%',
                transform: 'translate(-50%, -50%) rotate(190deg);',
                zIndex: 2,
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 294.394 98.726">
                <path
                  d="M239.972 95.168c-29.55 6.52-83.26 4.964-99.002-12.144-9.489-10.081-16.086-16.763-28.702-17.842q-2.055-.188-4.239-.175c-11.443.062-26.542 4.399-39.898 12.272-13.878 8.444-27.037 12.525-41.056 11.772-27.91-1.76-34.39-19.34-18.75-37.236C23.963 33.576 62.84 17.374 84.814 16.98c12.222-.175 21.998 3.982 25.418 11.488 2.865 7.02 9.31 10.95 19.857 10.96q1.953.003 4.054-.176c12.371-1.115 28.287-6.906 35.015-15.73C179.593 9.462 176.08-1.42 190.154.151c11.165 1.357 28.304 10.608 45.132 21.402 14.592 9.551 31.558 20.036 45.585 28.335 14.153 8.32 17.493 14.618 8.615 22.911-10.346 9.363-24.227 17.432-49.514 22.369Z"
                  fill={blue[100]}
                />
              </svg>
            </Box>
            <Box
              sx={{
                width: {xs: '100%', md: '380px', lg: '520px'},
                maxWidth: {xs: '380px', md: 'none'},
                position: {xs: 'relative', md: 'absolute'},
                top: {xs: 'auto', md: '50%'},
                left: {xs: 'auto', md: '50%'},
                transform: {xs: 'none', md: 'translate(0, -50%)'},
                mt: {xs: 4, md: -1},
                px: {xs: 2, md: 0},
                zIndex: 3,
              }}
            >
              <img src={topImage} alt="トップ" style={{ verticalAlign: 'top',}} />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mb: {xs: 6, md: 3},
                px: {xs: 0, lg: 2,},
                maxWidth: '1240px',
                marginLeft: 'auto',
                marginRight: 'auto',
                position: 'relative',
                zIndex: 4,
              }}
            >
              <Box sx={{ mt: {xs: 3, md: 0,} }}>
                <Typography
                  sx={{
                    color: 'secondary.light',
                    fontWeight: 'bold',
                    mb: 1,
                    fontSize: '0.75rem',
                    textAlign: {xs: 'center', md: 'left'},
                  }}
                >
                  ファッション R-SEARCH
                </Typography>
                <Typography
                  sx={{
                    color: 'secondary.dark',
                    fontSize: { xs:'30px', md: '40px', lg: '50px', },
                    fontWeight: 'bold',
                    lineHeight: { xs:'1.4', md: '1.35', lg: '1.3' },
                    display: 'flex',
                    transform: 'translateX(-3px)',
                    fontFeatureSettings: "'palt'",
                    letterSpacing: '0.1em',
                    textAlign: {xs: 'center', md: 'left'},
                  }}
                >
                  ファッションアイテム<br />まとめて検索
                </Typography>
                <Typography
                  sx={{
                    color: 'secondary.dark',
                    fontWeight: 'bold',
                    mt: 1.5,
                    letterSpacing: '0.1em',
                    fontFamily: [
                      'Roboto',
                      'sans-serif',
                    ].join(','),
                    textAlign: {xs: 'center', md: 'left'},
                  }}
                >
                  Search for fashion items at once
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    color: 'secondary.dark',
                    mt: 3.5,
                    letterSpacing: '0.1em',
                    textAlign: {xs: 'center', md: 'left'},
                  }}
                >
                  さあ、検索をはじめよう！
                </Typography>
                <Box sx={{ mt: 2, textAlign: {xs: 'center', md: 'left'}, }}>
                  <Button
                    component={NavLink}
                    to={`/search`}
                    variant="contained"
                    color='primary'
                    disableElevation
                  >
                    検索一覧へ
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            mt={-5}
            sx={{
              maxWidth: '1480px',
              marginLeft: 'auto',
              marginRight: 'auto',
              position: 'relative',
              zIndex: 2,
            }}
          >
            <Box my={2}>
              <Paper sx={{ p: 2, }}>
                <Alert
                  severity="info"
                  sx={{
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'info.main',
                  }}
                >
                  <AlertTitle sx={{ fontWeight: 'fontWeightBold', }}>ネットショッピングの商品検索をちょっと便利に。</AlertTitle>
                  <Typography
                    sx={{
                      display: {xs: 'inline', md: 'block', },
                      fontSize: 'inherit',
                      lineHeight: 'inherit',
                    }}
                  >
                    お気に入りのブランドの商品や季節モノの気になるアイテム、何度も検索条件を設定して探すのはとても面倒。
                  </Typography>
                  <Typography
                    sx={{
                      display: {xs: 'inline', md: 'block', },
                      fontSize: 'inherit',
                      lineHeight: 'inherit',
                    }}
                  >
                    当サイト「ファッション R-SEARCH」は、楽天商品検索APIを利用し、検索条件をまとめて設定して一気に検索する事が出来ます。
                  </Typography>
                </Alert>
              </Paper>
            </Box>
          </Box>
          <Box component="section" mt={{ xs: 5, md: 10, }} sx={{ mt: 8 }}>
            <Typography
              component="h2"
              variant="h2"
              sx={{
                color: 'secondary.dark',
                fontSize: { xs: '24px', md: '32px', lg: '40px', },
                fontWeight: 'bold',
                lineHeight: { xs:'1.45', md: '1.4', lg: '1.35' },
                fontFeatureSettings: "'palt'",
                letterSpacing: '0.1em',
                textAlign: 'center',
              }}
            >
              こんな人におすすめ
            </Typography>
            <Box
              mt={{ xs: 4, md: 8, }}
              sx={{
                maxWidth: '1480px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Paper>
                <Box p={{ xs: 2, md: 3, lg: 4, }}>
                  <Grid container spacing={{ xs: 2, md: 3, lg: 4, }} sx={{ alignItems: 'center' }}>
                    <Grid item xs={12} lg={6}>
                      <Box sx={{ overflow: 'hidden', borderRadius: {xs: '9px', md: '18px'}, }}>
                        <LazyLoadImage
                          src={recommendImage1}
                          alt='こんな人におすすめ'
                          width='100%'
                          height='auto'
                          style={{ verticalAlign: 'top',}}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Box mb={{ xs: 2, md: 3, lg: 4, }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', }}>
                          <Typography
                            component='span'
                            sx={{
                              color: 'secondary.dark',
                              fontSize: { xs: '32px', md: '48px', lg: '60px', },
                              fontWeight: 'bold',
                              lineHeight: '1.2',
                              fontFamily: [
                                'Roboto',
                                'sans-serif',
                              ].join(','),
                            }}
                          >
                            01
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              ml: { xs: 1, md: 2, },
                              pl: { xs: 1, md: 2, },
                              position: 'relative',
                              '&::before': {
                                content: '""',
                                display: 'block',
                                width: '1px',
                                height: 'calc(100% - 4px)',
                                position: 'absolute',
                                backgroundColor: 'secondary.main',
                                top: '50%',
                                left: 0,
                                transform: 'translateY(-50%)',
                              },
                            }}
                          >
                            <Typography
                              component='span'
                              sx={{
                                color: 'secondary.light',
                                fontSize: { xs: '9px', md: '12px', lg: '15px', },
                                fontWeight: 'bold',
                                lineHeight: '1.2',
                                fontFamily: [
                                  'Roboto',
                                  'sans-serif',
                                ].join(','),
                              }}
                            >
                              CASE
                            </Typography>
                            <Typography
                              component='h3'
                              variant="h3"
                              sx={{
                                color: 'secondary.dark',
                                fontSize: { xs: '18px', md: '24px', lg: '30px', },
                                fontWeight: 'bold',
                                lineHeight: { xs:'1.4', md: '1.35', lg: '1.3' },
                                mt: { xs: 0.5, md: 0.75, lg: 1 },
                                
                              }}
                            >
                              好きなブランドがたくさんある
                            </Typography>
                          </Box>
                        </Box>
                        <Typography
                          variant='body1'
                          sx={{
                            mt: 2,
                          }}
                        >
                          ログイン後、複数の好きなブランド名を検索条件に登録しておくことができます。日々の入荷や更新情報をまとめて調べるのに役立ちます。
                        </Typography>
                      </Box>
                      <Divider />
                      <Box mt={{ xs: 2, md: 3, lg: 4, }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', }}>
                          <Typography
                            component='span'
                            sx={{
                              color: 'secondary.dark',
                              fontSize: { xs: '32px', md: '48px', lg: '60px', },
                              fontWeight: 'bold',
                              lineHeight: '1.2',
                              fontFamily: [
                                'Roboto',
                                'sans-serif',
                              ].join(','),
                            }}
                          >
                            02
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              ml: { xs: 1, md: 2, },
                              pl: { xs: 1, md: 2, },
                              position: 'relative',
                              '&::before': {
                                content: '""',
                                display: 'block',
                                width: '1px',
                                height: 'calc(100% - 4px)',
                                position: 'absolute',
                                backgroundColor: 'secondary.main',
                                top: '50%',
                                left: 0,
                                transform: 'translateY(-50%)',
                              },
                            }}
                          >
                            <Typography
                              component='span'
                              sx={{
                                color: 'secondary.light',
                                fontSize: { xs: '9px', md: '12px', lg: '15px', },
                                fontWeight: 'bold',
                                lineHeight: '1.2',
                                fontFamily: [
                                  'Roboto',
                                  'sans-serif',
                                ].join(','),
                              }}
                            >
                              CASE
                            </Typography>
                            <Typography
                              component='h3'
                              variant="h3"
                              sx={{
                                color: 'secondary.dark',
                                fontSize: { xs: '18px', md: '24px', lg: '30px', },
                                fontWeight: 'bold',
                                lineHeight: { xs:'1.4', md: '1.35', lg: '1.3' },
                                mt: { xs: 0.5, md: 0.75, lg: 1 },
                                
                              }}
                            >
                              欲しいアイテムがいろいろある
                            </Typography>
                          </Box>
                        </Box>
                        <Typography
                          variant='body1'
                          sx={{
                            mt: 2,
                          }}
                        >
                          Tシャツ、スウェット、パンツ等々、探したいファッションアイテムがたくさんある場合、複数の条件を設定して検索することで、まとめて欲しいアイテムの一覧に辿り着けます。
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
          </Box>
          <Box
            component="section"
            mt={{ xs: 5, md: 10, }}
            mx={{ xs: -2, md: -3, }}
            px={{ xs: 2, md: 3, }}
            py={{ xs: 4, md: 8, }}
            sx={{
              backgroundColor: '#fff',
            }}
          >
            <Typography
              component="h2"
              sx={{
                color: 'secondary.dark',
                fontSize: { xs: '24px', md: '32px', lg: '40px', },
                fontWeight: 'bold',
                lineHeight: { xs:'1.45', md: '1.4', lg: '1.35' },
                fontFeatureSettings: "'palt'",
                letterSpacing: '0.1em',
                textAlign: 'center',
              }}
            >
              おすすめブランド
            </Typography>
            <Box
              mt={{ xs: 4, md: 8, }}
              sx={{
                maxWidth: '1480px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Grid container spacing={4}>
                {Object.keys(listData).map((prop) => {
                  return (
                    <Grid item xs={12} md={6} key={prop}>
                      <Typography
                        component="h2"
                        sx={{
                          color: 'secondary.dark',
                          fontSize: { xs: '18px', md: '20px', },
                          fontWeight: 'bold',
                          lineHeight: '1.2',
                          fontFeatureSettings: "'palt'",
                          letterSpacing: '0.05em',
                          textAlign: 'center',
                          fontFamily: [
                            'Roboto',
                            'sans-serif',
                          ].join(','),
                        }}
                      >
                        {prop === 'mens' ? "Men's" : "Women's"}
                      </Typography>
                      <Box mt={{ xs: 2, md: 4, }}>
                        <Box m='-4px'>
                          {Object.keys(listData[prop]).sort().map((childProp) => {
                            return (
                              <Button
                                variant="outlined"
                                size="small"
                                component={NavLink}
                                to={`/list/${prop}/${childProp}`}
                                key={childProp}
                                sx={{
                                  m: '4px',
                                  fontFamily: [
                                    'Roboto',
                                    'sans-serif',
                                  ].join(','),
                                }}
                              >
                                {listData[prop][childProp].en}
                              </Button>
                            )
                          })}
                        </Box>
                      </Box>
                    </Grid>
                  )
                })}          
              </Grid>
            </Box>
          </Box>
        </Box>
      </Layout>
    </React.Fragment>
  );
  
};

export default Top;
