//component
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Head(props) {

  const { pageTitle } = props
  const { pageDesc } = props
  const { pageUrl } = props

  return (
    <HelmetProvider>
      <Helmet>

        <title>{pageTitle}</title>

        <meta
          name="description"
          content={pageDesc}
        />
        
        <link rel="canonical" href={pageUrl} />

        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDesc} />

      </Helmet>
    </HelmetProvider>
  );

};

export default Head;
