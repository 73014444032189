//React
import { BrowserRouter as Router, Route } from "react-router-dom";

//Firebase
import { analytics } from './component/firebase';
import { logEvent } from "firebase/analytics";

//MUI
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

//Auth
import AuthProvider from './component/auth';

//Page
import Top from './page/Top';
import Search from './page/Search';
import Setting from './page/Setting';
import Selection from './page/Selection';
import List from './page/List';
import SignIn from './page/SignIn';
import SignInLink from './page/SignInLink';

//font
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/700.css';

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#f4f4f4',
          letterSpacing: '0.05em',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#d50000',
    },
    secondary: {
      main: '#1c4b72',
    },
    error: {
      main: '#e2187d',
    },
    google: {
      main: '#757575',
    },
  },
  typography: {
    fontFamily: [
      '"Hiragino Kaku Gothic ProN"',
      '"Hiragino Sans"',
      'Meiryo',
      'sans-serif',
    ].join(','),
    button: {
      fontWeight: 700,
      textTransform: 'none',
    },
  },
});

function App() {

  logEvent(analytics, 'notification_received');

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Route exact path="/" component={Top} />
          <Route path="/search" component={Search} />
          <Route path="/setting" component={Setting} />
          <Route path="/selection/:docId" component={Selection} />
          <Route path="/list/:genre/:keyword" component={List} />
          <Route path="/signin" component={SignIn} />
          <Route path="/signinlink" component={SignInLink} />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
