//React
import React, { useState, useEffect } from 'react';
import { useHistory, Redirect, Link as RouterLink, useLocation } from 'react-router-dom';

//Firebase
import { auth } from '../component/firebase';
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";

//Auth
import { useAuthContext } from '../component/auth';

//component
import Head from "../component/Head";
import Layout from '../component/Layout';

//MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

//Icon
import AssignmentIcon from '@mui/icons-material/Assignment';

const SignInLinkContents = (props) => {

  const pageUrl = process.env.REACT_APP_API_ENDPOINT;

  const history = useHistory();

  const location = props.location;

  const [emailStatus, setEmailStatus] = useState({value: ''});
  const [alertStatus, setAlertStatus] = useState({open: false});

  const handleChangeEmail = (event) => {
    if (event.currentTarget.value.length > 0) {
      setEmailStatus({
        value: event.currentTarget.value,
      });
    } else {
      setEmailStatus({});
    }
  };

  const handleSignIn = (email) => {
    signInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        window.localStorage.removeItem('emailForSignIn');
        history.push('/');
      })
      .catch((error) => {
        setAlertStatus({
          open: true,
          type: 'error',
          title: '登録できませんでした',
          message: `${error.message}`,
        });
                
        switch(error.code) {
          case 'auth/missing-email':
            setEmailStatus({
              error: true,
              helperText: 'メールアドレスが入力されていません',
            });
            break;
          case 'auth/invalid-email':
            setEmailStatus({
              value: email,
              error: true,
              helperText: 'メールアドレスの形式が正しくありません',
            });
            break;
          case 'auth/invalid-action-code':
            setEmailStatus({
              value: email,
              error: true,
              helperText: 'ログイン用リンクが既に無効となっています',
            });
            break;
          default:
            setEmailStatus({
              error: true,
            });
            break;
        }

      });
  }
  
  const handleSubmit = (event, email) => {
    event.preventDefault();
    handleSignIn(email);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertStatus({
      open: false,
    });
  };

  useEffect(() => {

    const storageEmail = window.localStorage.getItem('emailForSignIn');
    if (storageEmail) {
      setEmailStatus({
        value: storageEmail,
      });
      handleSignIn(storageEmail);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isSignInWithEmailLink(auth, window.location.href)) return <Redirect to="/" />;

  return (
    <React.Fragment>
      <Head
        pageTitle="ログイン | 楽天市場のファッションアイテムまとめて検索 ファッション R-SEARCH"
        pageDesc={`ファッションR-SEARCHは楽天市場でのファッションアイテムに関する商品検索を少し便利にするサイトです。`}
        pageUrl={`${pageUrl}${location.pathname}`}
      />
      <Layout>
        <Container
          sx={{
            minHeight: { xs: 'calc(100vh - 56px - 60px)', md: 'calc(100vh - 64px - 60px)', },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: { xs: 0, },
            py: { xs: 2, md: 4},
          }}
        >
          <Grid
            container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid item>
              <Card sx={{ maxWidth: 520, }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: 'primary.main', }}>
                      <AssignmentIcon />
                    </Avatar>
                  }
                  title='ログイン'
                  titleTypographyProps={{
                    component: 'h1',
                    variant: 'h6',
                    sx: { fontWeight: 'fontWeightBold', }
                  }}
                  sx={{ borderBottom: 1, borderColor: 'divider', }}
                />
                <CardContent>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={(event) => handleSubmit(event, emailStatus.value)}
                    mb={1}
                  >
                    <Typography variant='body2' my={1}>確認のため、新規登録時に入力したメールアドレスと同じものを入力してください。</Typography>
                    <TextField
                      type="email"
                      name="email"
                      label="メールアドレス"
                      variant="outlined"
                      fullWidth
                      required
                      error={emailStatus.error}
                      helperText={emailStatus.helperText}
                      margin="normal"
                      onChange={(event) => handleChangeEmail(event)}
                      value={emailStatus.value}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      disableElevation
                      fullWidth
                      sx={{
                        mt: 2.5,
                        mb: 1.5,
                        py: 1.125,
                        px: 2.125,
                      }}
                    >
                      ログイン
                    </Button>
                    <Grid container direction={{ xs: 'column', }}>
                      <Grid
                        item
                        xs
                        sx={{ textAlign: { xs: 'right', }, }}
                      >
                        <Link
                          component={RouterLink}
                          to="/signin"
                          variant="body2"
                        >
                          ログインページ
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Snackbar
            open={alertStatus.open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={6000}
            onClose={handleAlertClose}
            sx={{ bottom: 50, }}
          >
            <Alert          
              severity={alertStatus.type}
              onClose={handleAlertClose}
              sx={{
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: `${alertStatus.type}.main`,
              }}
            >
              {alertStatus.title && <AlertTitle sx={{ fontWeight: 'fontWeightBold', }}>{alertStatus.title}</AlertTitle> }
              {alertStatus.message}
            </Alert>
          </Snackbar>
        </Container>
      </Layout>
    </React.Fragment>
  );
  
};

//SignInLink
function SignInLink (props) {

  const location = useLocation();

  const { isSignedIn } = useAuthContext();

  if (location.pathname.slice(-1) === '/') return <Redirect to={location.pathname.slice(0, -1)} />

  if (isSignedIn) return <Redirect to="/" />;

  return (
    <SignInLinkContents location={location}>
      {props.children}
    </SignInLinkContents>
  );

}

export default SignInLink;
