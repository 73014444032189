//React
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

//MUI
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';

//Icon
import MenuIcon from '@mui/icons-material/Menu';

//component
import logo from '../image/logo.svg';
import DrawerContents from './DrawerContents';
import { Typography } from '@mui/material';

function Layout(props) {

  const [mobileOpen, setMobileOpen] = useState(false);

  const drawerWidth = 240;

  const location = window.location;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: 'flex', }}>
      <AppBar
        elevation={0}
        position="fixed"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' }, }}
          >
            <MenuIcon />
          </IconButton>
          <Typography component={location.pathname === '/' ? 'h1' : 'p'}>
            <Link
              component={RouterLink}
              to="/"
              sx={{
                width: {xs: '90px', md: '120px',},
                height: 0,
                display: 'block',
                overflow: 'hidden',
                position: 'relative',
                pt: {xs: '22.6098px', md: '30.1461px',},
                '&::after': {
                  content: '""',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${logo})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                },
              }}
            >
              楽天市場のファッションアイテムまとめて検索 ファッション R-SEARCH
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="aside"
        sx={{
          width: { md: drawerWidth, },
          flexShrink: { md: 0, },
          zIndex: 1,
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true, }}
          sx={{ display: { xs: 'block', md: 'none', }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, }, }}
        >
          <DrawerContents drawerToggle={() => handleDrawerToggle()} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{ display: { xs: 'none', md: 'block' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, }, }}
          open
        >
          <Toolbar />
          <DrawerContents />
        </Drawer>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          px: { xs: 2, md: 3 },
          width: { md: `calc(100% - ${drawerWidth}px)`, },
          boxSizing: 'border-box',
        }}
      >
        <Toolbar />
        <Box
          component="main"
        >
          {props.children}
        </Box>
        <Box
          component="footer"
          sx={{
            display: 'flex',
            alignItems: 'center',
            bgcolor: 'grey.300',
            height: '60px',
            px: { xs: 2, md: 3 },
            mx: { xs: -2, md: -3 },
          }}
        >
          <Typography>
            <Typography
              component='small'
              sx={{
                fontSize: '10px',
                color: 'text.secondary',
                fontFamily: [
                  'Roboto',
                  'sans-serif',
                ].join(','),
                lineHeight: 1.4,
                display: 'block'
              }}
            >Copyright © 2020 fashion-r-search.com All Rights Reserved.</Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
  
};

export default Layout;
