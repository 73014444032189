//React
import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

//Firebase
import { db } from '../component/firebase';
import { collection, doc, query, orderBy, getDocs, deleteDoc, Timestamp, writeBatch } from "firebase/firestore";

//Auth
import { useAuthContext } from '../component/auth';

//MUI
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import { blue } from '@mui/material/colors';

//Icon
import SearchIcon from '@mui/icons-material/Search';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

//component
import Head from "../component/Head";
import Layout from '../component/Layout';

const SettingContents = (props) => {

  const pageUrl = process.env.REACT_APP_API_ENDPOINT;

  const isSignedIn = props.isSignedIn;

  const location = props.location;

  const [searchData, setSearchData] = useState([]);
  const [alertStatus, setAlertStatus] = useState({ open: false });

  const createRandomString = () => {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for ( var i = 0; i < 28; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  };

  const getGenre = async (value, id) => {
    const url = 'https://app.rakuten.co.jp/services/api/IchibaGenre/Search/20140222'
    const format = 'json';
    const genre = value;
    const appId = 'dbf369197285af3ed218d89c868135f2';
    try {
      const response = await fetch (`${url}?format=${format}&genreId=${genre}&applicationId=${appId}`);
      const json = await response.json();
      if (json.children.length !== 0) {
        const genreArray = {...json.children};
        const newSearchData = searchData.map((searchDataItem) => {
          if (searchDataItem.id === id) {
            searchDataItem.genreData = [...searchDataItem.genreData, genreArray];
            searchDataItem.productData = [];
            searchDataItem.errorData = [];
            searchDataItem.page = 0;
            searchDataItem.prev = true;
          }
          return searchDataItem;
        });
        setSearchData([...newSearchData]);
      }
    }
    catch(error) {
      setAlertStatus({
        open: true,
        type: 'error',
        title: 'ジャンルが取得できませんでした',
        message: `${error}`,
      });
    }
  };

  const getSearchConfig = async () => {
    try {
      const configQuery = query(collection(db, 'users', isSignedIn.uid, 'config'), orderBy('rank'));
      const querySnapshot = await getDocs(configQuery);
      if (querySnapshot.size) {
        handleApplySearchClick(querySnapshot);
      } else {
        handleAddSearchData(searchData.length + 1);
      }
    }
    catch(error) {
      setAlertStatus({
        open: true,
        type: 'error',
        title: '保存した検索条件が取得できませんでした',
        message: `${error}`,
      });
    }
  }

  //保存済み検索設定
  const handleApplySearchClick = (querySnapshotData) => {
    const newSearchData = [];
    querySnapshotData.forEach((doc) => {
      const docData = doc.data();
      const addSearchData = {};
      addSearchData['savedDocId'] = doc.id;
      addSearchData['createDate'] = docData.createDate;
      addSearchData['rank'] = docData.rank;
      addSearchData['id'] = docData.id;
      addSearchData['searchValue'] = docData.searchValue;
      addSearchData['genreData'] = docData.genreData;
      addSearchData['productData'] = [];
      addSearchData['errorData'] = [];
      addSearchData['page'] = 0;
      addSearchData['prev'] = true;
      newSearchData.push(addSearchData);
    });
    setSearchData([...newSearchData]);
  };

  //検索設定
  const handleAddSearchData = (index) => {
    const addSearchData = {};
    addSearchData['createDate'] = isSignedIn ? Timestamp.fromDate(new Date()) : '';
    addSearchData['rank'] = index;
    addSearchData['id'] = createRandomString();
    addSearchData['searchValue'] = {
      keyword: '',
      genre: [],
      sort: '',
    };
    addSearchData['genreData'] = [];
    addSearchData['productData'] = [];
    addSearchData['errorData'] = [];
    addSearchData['page'] = 0;
    addSearchData['prev'] = true;
    setSearchData([...searchData, addSearchData]);
  };

  const handleRemoveSearchData = (searchDataObj) => {
    const newSearchData = searchData.filter((item) => item.id !== searchDataObj.id);
    setSearchData([...newSearchData]);
  }

  const handleKeywordChange = (event, id) => {
    const newSearchData = searchData.map((item) => {
      if (item.id === id) {
        item.searchValue.keyword = event.target.value;
        item.productData = [];
        item.errorData = [];
        item.page = 0;
        item.prev = true;
      }
      return item;
    });
    setSearchData([...newSearchData]);
  };

  const handleGenreChange = (event, index, id) => {
    const newSearchData = searchData.map((item) => {
      if (item.id === id) {
        const newGenreValue = [...item.searchValue.genre];
        newGenreValue[index] = event.target.value;
        newGenreValue.splice(index + 1);
        if (!event.target.value) {
          newGenreValue.splice(-1,1);
        }
        item.searchValue.genre = newGenreValue;
        item.productData = [];
        item.errorData = [];
        item.page = 0;
        item.prev = true;
        item.genreData.splice(index);
      }
      return item;
    });
    setSearchData([...newSearchData]);
    if (event.target.value) {
      getGenre(event.target.value, id);
    } 
  };

  const handleSortChange = (event, id) => {
    const newSearchData = searchData.map((item) => {
      if (item.id === id) {
        item.searchValue.sort = event.target.value;
        item.productData = [];
        item.errorData = [];
        item.page = 0;
        item.prev = true;
      }
      return item;
    });
    setSearchData([...newSearchData]);
  };

  const handleDeleteSearchConditionsClick = async (searchDataObj) => {
    if (!searchDataObj.savedDocId) {
      handleRemoveSearchData(searchDataObj);
      return false;
    }
    try {
      //delete
      const configDocRef = doc(db, 'users', isSignedIn.uid, 'config', searchDataObj.savedDocId);
      await deleteDoc(configDocRef);
      handleRemoveSearchData(searchDataObj);
      setAlertStatus({
        open: true,
        type: 'success',
        message: '検索条件を削除しました',
      });
    }
    catch(error) {
      setAlertStatus({
        open: true,
        type: 'error',
        title: '検索条件の削除に失敗しました',
        message: `${error}`,
      });
    }
  }

  const handleSaveSearchConditionsClick = async () => {
    try {
      const batch = writeBatch(db);
      searchData.forEach((searchDataItem) => {
        const configDocRef = searchDataItem.savedDocId ? doc(db, 'users', isSignedIn.uid, 'config', searchDataItem.savedDocId) : doc(collection(db, 'users', isSignedIn.uid, 'config'));
        const configData = {
          createDate: searchDataItem.createDate,
          rank: searchDataItem.rank,
          id: searchDataItem.id,
          searchValue: searchDataItem.searchValue,
          genreData: searchDataItem.genreData,
        };
        if (!searchDataItem.savedDocId) {
          searchDataItem.savedDocId = configDocRef.id;
        }
        batch.set(configDocRef, configData);
      });
      await batch.commit();
      setAlertStatus({
        open: true,
        type: 'success',
        message: '検索条件を保存しました',
      });
    }
    catch(error) {
      setAlertStatus({
        open: true,
        type: 'error',
        title: '検索条件の保存に失敗しました',
        message: `${error}`,
      });
    }
  };

  //alert
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertStatus({
      open: false,
    });
  };

  useEffect(() => {

    if (isSignedIn) {
      getSearchConfig();
    } else {
      handleAddSearchData(searchData.length + 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Head
        pageTitle="検索条件設定 | 楽天市場のファッションアイテムまとめて検索 ファッション R-SEARCH"
        pageDesc={`ファッションR-SEARCHは楽天市場でのファッションアイテムに関する商品検索を少し便利にするサイトです。`}
        pageUrl={`${pageUrl}${location.pathname}`}
      />
      <Layout>
        <Box component='section' sx={{ pb: 2, minHeight: { xs: 'calc(100vh - 56px - 60px)', md: 'calc(100vh - 64px - 60px)', }}}>
          <Box
            mx={{ xs: -2, md: -3 }}
            p={{ xs: 2, md: 3 }}
            sx={{
              height: '320px',
              backgroundColor: blue[50],
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              zIndex: 1,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                width:  { xs:'240%', sm:'180%', md: '120%', },
                position: 'absolute',
                top: '0%',
                right: '0%',
                transform: 'translate(50%, -50%) rotate(15deg);',
                zIndex: 1,
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 294.394 98.726">
                <path
                  d="M239.972 95.168c-29.55 6.52-83.26 4.964-99.002-12.144-9.489-10.081-16.086-16.763-28.702-17.842q-2.055-.188-4.239-.175c-11.443.062-26.542 4.399-39.898 12.272-13.878 8.444-27.037 12.525-41.056 11.772-27.91-1.76-34.39-19.34-18.75-37.236C23.963 33.576 62.84 17.374 84.814 16.98c12.222-.175 21.998 3.982 25.418 11.488 2.865 7.02 9.31 10.95 19.857 10.96q1.953.003 4.054-.176c12.371-1.115 28.287-6.906 35.015-15.73C179.593 9.462 176.08-1.42 190.154.151c11.165 1.357 28.304 10.608 45.132 21.402 14.592 9.551 31.558 20.036 45.585 28.335 14.153 8.32 17.493 14.618 8.615 22.911-10.346 9.363-24.227 17.432-49.514 22.369Z"
                  fill={blue[100]}
                />
              </svg>
            </Box>
            <Box
              sx={{
                width: { xs:'200%', sm:'150%', md: '100%', },
                position: 'absolute',
                top: '100%',
                left: '0%',
                transform: 'translate(-50%, -50%) rotate(190deg);',
                zIndex: 2,
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 294.394 98.726">
                <path
                  d="M239.972 95.168c-29.55 6.52-83.26 4.964-99.002-12.144-9.489-10.081-16.086-16.763-28.702-17.842q-2.055-.188-4.239-.175c-11.443.062-26.542 4.399-39.898 12.272-13.878 8.444-27.037 12.525-41.056 11.772-27.91-1.76-34.39-19.34-18.75-37.236C23.963 33.576 62.84 17.374 84.814 16.98c12.222-.175 21.998 3.982 25.418 11.488 2.865 7.02 9.31 10.95 19.857 10.96q1.953.003 4.054-.176c12.371-1.115 28.287-6.906 35.015-15.73C179.593 9.462 176.08-1.42 190.154.151c11.165 1.357 28.304 10.608 45.132 21.402 14.592 9.551 31.558 20.036 45.585 28.335 14.153 8.32 17.493 14.618 8.615 22.911-10.346 9.363-24.227 17.432-49.514 22.369Z"
                  fill={blue[100]}
                />
              </svg>
            </Box>
            <Box
              sx={{
                px: 2,
                mb: 3,
                position: 'relative',
                zIndex: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                component="h1"
                sx={{
                  color: 'secondary.dark',
                  fontSize: { xs:'30px', md: '40px', },
                  fontWeight: 'bold',
                  lineHeight: { xs:'1.4', md: '1.35', },
                  fontFeatureSettings: "'palt'",
                  letterSpacing: '0.1em',
                }}
              >
                検索条件設定
              </Typography>
              <Typography
                sx={{
                  color: 'secondary.dark',
                  fontWeight: 'bold',
                  mt: 1.5,
                  letterSpacing: '0.1em',
                  fontFamily: [
                    'Roboto',
                    'sans-serif',
                  ].join(','),
                }}
              >
                Search condition setting
              </Typography>
            </Box>
          </Box>
          <Box
            mt={-5}
            sx={{
              maxWidth: '1480px',
              marginLeft: 'auto',
              marginRight: 'auto',
              position: 'relative',
              zIndex: 2,
            }}
          >
            {searchData.length === 0 && (
              <Box my={2}>
                <Paper sx={{ p: 2, }}>
                  <Alert
                    severity="info"
                    sx={{
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: 'info.main',
                    }}
                  >
                    <AlertTitle sx={{ fontWeight: 'fontWeightBold', }}>検索条件を追加しましょう</AlertTitle>
                    下の検索条件を追加ボタンを押して、検索条件を追加してください。
                  </Alert>
                </Paper>
              </Box>
            )}
            <Box>
              {searchData.map((searchDataObj, index) => {
                const searchIndex = index + 1;
                return (
                  <Box my={2} key={searchDataObj.id}>
                    <Paper sx={{ p: 2, }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mx: -2,
                          mt: -2,
                          mb: 2,
                          p: 2,
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: 'divider',
                        }}
                      >
                        <SearchIcon />
                        <Typography
                          component='p'
                          variant='h6'
                          sx={{
                            flexGrow: 1,
                            fontWeight: 'fontWeightBold',
                            ml: 1,
                          }}
                        >
                          検索条件&nbsp;{searchIndex}
                        </Typography>
                      </Box>
                      <Box py={0.5}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="keyword">検索ワード</InputLabel>
                              <OutlinedInput
                                id="keyword"
                                label="検索ワード"
                                value={searchDataObj.searchValue.keyword}
                                onChange={(event) => handleKeywordChange(event, searchDataObj.id)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <InputLabel id="genre">ジャンル</InputLabel>
                              <Select
                                labelId="genre"
                                label="ジャンル"
                                value={searchDataObj.searchValue.genre.length > 0 ? searchDataObj.searchValue.genre[0] : ''}
                                onChange={(event) => handleGenreChange(event, 0, searchDataObj.id)}
                              >
                                <MenuItem value="">未選択</MenuItem>
                                <MenuItem value={100371}>レディースファッション</MenuItem>
                                <MenuItem value={551177}>メンズファッション</MenuItem>
                                <MenuItem value={558885}>靴</MenuItem>
                                <MenuItem value={558929}>腕時計</MenuItem>
                                <MenuItem value={216129}>ジュエリー・アクセサリー</MenuItem>
                                <MenuItem value={216131}>バッグ・小物・ブランド雑貨</MenuItem>
                              </Select>
                            </FormControl>
                            {searchDataObj.genreData.map((genreDataArray, index) => {
                              const genreIndex = index + 1;
                              return (
                                <Box mt={2} key={genreIndex}>
                                  <FormControl fullWidth>
                                    <InputLabel id={`${searchDataObj.id}_genre${genreIndex}`}>ジャンル</InputLabel>
                                    <Select
                                      labelId={`${searchDataObj.id}_genre${genreIndex}`}
                                      label="ジャンル"
                                      value={searchDataObj.searchValue.genre[genreIndex] ? searchDataObj.searchValue.genre[genreIndex] : ''}
                                      onChange={(event) => handleGenreChange(event, genreIndex, searchDataObj.id)}
                                    >
                                      <MenuItem value="">未選択</MenuItem>
                                      {Object.keys(genreDataArray).map((item) => {
                                        return (
                                          <MenuItem value={genreDataArray[item].child.genreId} key={genreDataArray[item].child.genreId}>{genreDataArray[item].child.genreName}</MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </Box>
                              );
                            })}
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <InputLabel id="sort">並び順</InputLabel>
                              <Select
                                labelId="sort"
                                label="並び順"
                                value={searchDataObj.searchValue.sort}
                                onChange={(event) => handleSortChange(event, searchDataObj.id)}
                              >
                                <MenuItem value="">未選択</MenuItem>
                                <MenuItem value={'+itemPrice'}>価格が低い順</MenuItem>
                                <MenuItem value={'-itemPrice'}>価格順が高い順</MenuItem>
                                <MenuItem value={'+updateTimestamp'}>更新日が古い順</MenuItem>
                                <MenuItem value={'-updateTimestamp'}>更新日が新しい</MenuItem>
                                <MenuItem value={'standard'}>楽天標準ソート順</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>            
                        </Grid>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          mx: -2,
                          mt: 2,
                          mb: -2,
                          p: 2,
                          borderTopStyle: 'solid',
                          borderTopWidth: '1px',
                          borderTopColor: 'divider',
                        }}
                      >
                        <Stack direction="row" spacing={1}>
                          <Button
                            color='secondary'
                            size="small"
                            startIcon={<DeleteForeverOutlinedIcon sx={{ mr: -0.5, }} />}
                            onClick={() => handleDeleteSearchConditionsClick(searchDataObj)}
                          >
                            削除
                          </Button>
                        </Stack>
                      </Box>
                    </Paper>
                  </Box>
                );
              })}
            </Box>
            <Box my={3}>
              <Button
                variant="contained"
                color='secondary'
                size="small"
                disableElevation
                onClick={() => handleAddSearchData(searchData.length + 1)}
              >
                検索条件を追加
              </Button>
            </Box>
            <Divider />
            <Box my={4} sx={{ display: 'flex', justifyContent: 'center', }}>
              <Stack direction="row" spacing={1}>
                <LoadingButton
                  color='primary'
                  variant="contained"
                  onClick={handleSaveSearchConditionsClick}
                  //loading={searchProgress}
                >
                  検索条件を登録
                </LoadingButton>
              </Stack>
            </Box>
          </Box>
        </Box>
        <Snackbar
          open={alertStatus.open}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          sx={{ bottom: 50, }}
        >
          <Alert          
            severity={alertStatus.type}
            onClose={handleAlertClose}
            sx={{
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: `${alertStatus.type}.main`,
            }}
          >
            {alertStatus.title && <AlertTitle sx={{ fontWeight: 'fontWeightBold', }}>{alertStatus.title}</AlertTitle> }
            {alertStatus.message}
          </Alert>
        </Snackbar>
      </Layout>
    </React.Fragment>
  );
  
};

//List
function Setting (props) {

  const location = useLocation();

  const { isSignedIn } = useAuthContext();

  if (location.pathname.slice(-1) === '/') return <Redirect to={location.pathname.slice(0, -1)} />

  if (!isSignedIn) return <Redirect to="/signin" />;

  return (
    <SettingContents isSignedIn={isSignedIn} location={location}>
      {props.children}
    </SettingContents>
  );

}

export default Setting;
