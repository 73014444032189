//React
import React, { useState } from 'react';
import { useHistory, Redirect, useLocation } from 'react-router-dom';

//Firebase
import { auth } from '../component/firebase';
import { sendSignInLinkToEmail, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

//Auth
import { useAuthContext } from '../component/auth';

//component
import Head from "../component/Head";
import Layout from '../component/Layout';

//MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import SvgIcon from '@mui/material/SvgIcon';

//Icon
import LockOpenIcon from '@mui/icons-material/LockOpen';


const GoogleIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M23.52 12.273a13.788 13.788 0 0 0-.218-2.455H12v4.642h6.458a5.52 5.52 0 0 1-2.394 3.622v3.01h3.878a11.703 11.703 0 0 0 3.578-8.82Z" style={{fill: '#4285f4', fillRule: 'evenodd'}} />
      <path d="M12 24a11.457 11.457 0 0 0 7.942-2.907l-3.878-3.011A7.24 7.24 0 0 1 5.285 14.28H1.276v3.11A11.995 11.995 0 0 0 12 24Z" style={{fill: '#34a853', fillRule: 'evenodd'}} />
      <path d="M5.285 14.28a7.094 7.094 0 0 1 0-4.56V6.61H1.276a12.015 12.015 0 0 0 0 10.78l4.01-3.11Z" style={{fill: '#fbbc05', fillRule: 'evenodd'}} />
      <path d="M12 4.773a6.483 6.483 0 0 1 4.587 1.794l3.442-3.442A11.533 11.533 0 0 0 12 0 11.995 11.995 0 0 0 1.276 6.61l4.01 3.11A7.152 7.152 0 0 1 12 4.773Z" style={{fill: '#ea4335', fillRule: 'evenodd'}} />
      <path d="M0 0h24v24H0Z" style={{fill: 'none'}} />
    </SvgIcon>
  );
}

const SignInContents = (props) => {

  const pageUrl = process.env.REACT_APP_API_ENDPOINT;

  const history = useHistory();

  const location = props.location;

  const [emailStatus, setEmailStatus] = useState({});
  const [alertStatus, setAlertStatus] = useState({});

  const actionCodeSettings = {
    url: `${pageUrl}/signinlink`,
    handleCodeInApp: true,
  };

  const handleChangeEmail = (event) => {
    if (event.currentTarget.value.length > 0) {
      setEmailStatus({
        value: event.currentTarget.value,
      });
    } else {
      setEmailStatus({});
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendSignInLinkToEmail(auth, emailStatus.value, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem('emailForEnmanSignIn', emailStatus.value);
        setAlertStatus({
          open: true,
          type: 'success',
          title: 'メールを送信しました',
          message: 'メールに記載のリンクからログインしてください',
        });
      })
      .catch((error) => {
        setAlertStatus({
          open: true,
          type: 'error',
          title: '登録できませんでした',
          message: `${error.message}`,
        });
                
        switch(error.code) {
          case 'auth/missing-email':
            setEmailStatus({
              error: true,
              helperText: 'メールアドレスが入力されていません',
            });
            break;
          case 'auth/invalid-email':
            setEmailStatus({
              value: emailStatus.value,
              error: true,
              helperText: 'メールアドレスの形式が正しくありません',
            });
            break;
          case 'auth/email-already-in-use':
            setEmailStatus({
              value: emailStatus.value,
              error: true,
              helperText: 'すでに登録済みのメールアドレスです',
            });
            break;
          default:
            setEmailStatus({
              error: true,
            });
            break;
        }

      });
  };
  
  const handleGoogleAuth = () => {
    auth.languageCode = 'ja';
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        history.push('/');
      }).catch((error) => {
        if ( error.code !== 'auth/popup-closed-by-user') {
          setAlertStatus({
            open: true,
            type: 'error',
            title: 'ログインできませんでした',
            message: `${error.message}`,
          });
        }
      });
  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertStatus({
      open: false,
    });
  };

  return (
    <React.Fragment>
      <Head
        pageTitle="ログイン | 楽天市場のファッションアイテムまとめて検索 ファッション R-SEARCH"
        pageDesc={`ファッションR-SEARCHは楽天市場でのファッションアイテムに関する商品検索を少し便利にするサイトです。`}
        pageUrl={`${pageUrl}${location.pathname}`}
      />
      <Layout>
        <Container
          sx={{
            minHeight: { xs: 'calc(100vh - 56px - 60px)', md: 'calc(100vh - 64px - 60px)', },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: { xs: 0, },
            py: { xs: 2, md: 4},
          }}
        >
          <Grid
            container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid item>
              <Card sx={{ maxWidth: 520, }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: 'primary.main', }}>
                      <LockOpenIcon />
                    </Avatar>
                  }
                  title="ログイン"
                  titleTypographyProps={{
                    component: 'h1',
                    variant: 'h6',
                    sx: { fontWeight: 'fontWeightBold', }
                  }}
                  sx={{ borderBottom: 1, borderColor: 'divider', }}
                />
                <CardContent>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <Typography variant='body2' my={1}>
                      メールアドレスを入力し、ログインボタンを押してください。ご入力いただいたメールアドレス宛にログイン用のリンクを送信します。
                    </Typography>
                    <TextField
                      type="email"
                      name="email"
                      label="メールアドレス"
                      variant="outlined"
                      fullWidth
                      required
                      error={emailStatus.error}
                      helperText={emailStatus.helperText}
                      margin="normal"
                      onChange={(event) => handleChangeEmail(event)}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      disableElevation
                      fullWidth
                      sx={{
                        mt: 2.5,
                        mb: 1.5,
                        py: 1.125,
                        px: 2.125,
                      }}
                    >
                      ログイン
                    </Button>
                  </Box>
                  <Divider sx={{ my: 3, }}>
                    <Chip label='または' />
                  </Divider>
                  <Box>
                    <Button
                      variant="outlined"
                      fullWidth
                      sx={{
                        mt: 2,
                        mb: 2,
                        py: 1,
                        px: 2,
                        fontFamily: [
                          'Roboto',
                          'sans-serif',
                        ].join(','),
                      }}
                      onClick={handleGoogleAuth}
                      color="google"
                    >
                      <GoogleIcon sx={{ fontSize: 18, mr: 2, }} />
                      Googleでログイン
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Snackbar
            open={alertStatus.open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={6000}
            onClose={handleAlertClose}
            sx={{ bottom: 50, }}
          >
            <Alert          
              severity={alertStatus.type}
              onClose={handleAlertClose}
              sx={{
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: `${alertStatus.type}.main`,
              }}
            >
              {alertStatus.title && <AlertTitle sx={{ fontWeight: 'fontWeightBold', }}>{alertStatus.title}</AlertTitle> }
              {alertStatus.message}
            </Alert>
          </Snackbar>
        </Container>
      </Layout>
    </React.Fragment>
  );
  
};

//SignIn
function SignIn (props) {

  const location = useLocation();

  const { isSignedIn } = useAuthContext();

  if (location.pathname.slice(-1) === '/') return <Redirect to={location.pathname.slice(0, -1)} />

  if (isSignedIn) return <Redirect to="/" />;

  return (
    <SignInContents location={location}>
      {props.children}
    </SignInContents>
  );

}

export default SignIn;
