//React
import { createContext, useState, useEffect, useContext } from 'react';

//Firebase
import { auth } from './firebase';
import { onAuthStateChanged } from "firebase/auth";


//MUI
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const AuthContext = createContext();

const useAuthContext = () => {
  return useContext(AuthContext);
}

function AuthProvider(props) {

  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const value = {
    isSignedIn,
    isLoading,
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setIsSignedIn(user);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  return (
    <AuthContext.Provider value={value}>
      {!isLoading && props.children}
    </AuthContext.Provider>
  );

};

export { useAuthContext };
export default AuthProvider;
