//React
import React, { useState } from 'react';
import { useHistory, useLocation, NavLink } from 'react-router-dom';

//Firebase
import { auth } from './firebase';
import { signOut } from "firebase/auth";

//Auth
import { useAuthContext } from './auth';

//MUI
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

//Icon
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

//component
import SavedList from '../component/SavedList';

//json 
import listData from '../json/list.json';

function DrawerContents(props) {

  const drawerToggleFunc = props.drawerToggle;

  const history = useHistory();
  const location = useLocation();
  const { isSignedIn } = useAuthContext();

  const [open, setOpen] = useState({
    womens: true,
    mens: true,
  });

  const menuList = [
    { label: 'ホーム', url: '/', icon: <HomeOutlinedIcon /> },
    { label: '検索一覧', url: '/search', icon: <ManageSearchOutlinedIcon /> },
    { label: '検索条件設定', url: '/setting', icon: <SettingsOutlinedIcon /> },
  ];

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        history.push('/signin');
      }).catch((error) => {
        console.log(error);
      });
  };

  const handleClick = (prop) => {
    setOpen({...open, [prop]: !open[prop]});
  };

  return (
    <React.Fragment>
      {isSignedIn ? (
        <React.Fragment>
          <Box
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Avatar src={isSignedIn.photoURL} alt={isSignedIn.displayName} />
            <Box
              sx={{
                pl: 2,
                wordBreak: 'break-all',
                width: 'calc(100% - 40px)',
              }}>
              <Typography component='p' variant="subtitle2">{isSignedIn.displayName}</Typography>
              <Typography
                component='p'
                variant="caption"
                sx={{
                  lineHeight: '1.2',
                  color: 'grey.600',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {isSignedIn.email}
              </Typography>
            </Box>
          </Box>
          <Divider />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Avatar>G</Avatar>
            <Box sx={{ ml: 2, wordBreak: 'break-all', }}>
              <Typography component='p' variant="subtitle2">ゲストさん</Typography>
            </Box>
          </Box>
          <Divider />
        </React.Fragment>
      )}
      <List component='div'>
        {isSignedIn ? (
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary='ログアウト' />
          </ListItemButton>
        ) : (
          <ListItemButton component={NavLink} to='/signin'>
            <ListItemIcon>
              <LoginIcon />
            </ListItemIcon>
            <ListItemText primary='ログイン' />
          </ListItemButton>
        )}
      </List>
      <Divider />
      <List component='nav'>
        {menuList.map((item, index) => {
          return (
            <ListItemButton
              component={NavLink}
              to={item.url}
              selected={item.url === location.pathname}
              key={index}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          )
        })}
      </List>
      <SavedList drawerToggle={drawerToggleFunc && drawerToggleFunc} />
      <Divider />
      <List component='nav'>
        {Object.keys(listData).map((prop) => {
          return (
            <React.Fragment key={prop}>
              <ListItemButton
                component='div'
                onClick={() => handleClick(prop)}
              >
                <ListItemText
                  primary={prop === 'mens' ? "Men's" : "Women's"}
                  primaryTypographyProps={{ fontSize: '0.875rem', fontWeight: 'bold', }}
                />
                {open[prop] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open[prop]} timeout="auto" unmountOnExit>
                <List component='div'>
                  {Object.keys(listData[prop]).sort().map((childProp) => {
                    return (
                      <ListItemButton
                        component={NavLink}
                        to={`/list/${prop}/${childProp}`}
                        key={childProp}
                        selected={`/list/${prop}/${childProp}` === location.pathname}
                        sx={{ fontSize: '0.75rem', }}
                        onClick={drawerToggleFunc && (() => drawerToggleFunc())}
                      >
                        {listData[prop][childProp].ja}
                      </ListItemButton>
                    )
                  })}
                </List>
              </Collapse>
            </React.Fragment>
          )
        })}
      </List>
    </React.Fragment>
  );
  
};

export default DrawerContents;
